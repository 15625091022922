import type { ResourceProps } from '@refinedev/core';
import type { RouteObject } from 'react-router-dom';
import { dashboardResource, dashboardRoutes } from './pages/dashboard';
import { skuManagementResource, skuManagementRoutes } from './pages/sku-management';
import { vendorResource, vendorRoutes } from './pages/vendor';
import { purchaseOrderResource, purchaseOrderRoutes } from './pages/purchase-order';
import { fakturPajakResource, fakturPajakRoutes } from './pages/faktur-pajak';
import { warehouseResource, warehouseRoutes } from './pages/warehouse';
import { poDropshipResource, poDropshipRoutes } from './pages/purchase-order-dropship';

export const pagesResources: ResourceProps[] = [
  ...dashboardResource,
  ...vendorResource,
  ...skuManagementResource,
  ...purchaseOrderResource,
  ...fakturPajakResource,
  ...warehouseResource,
  ...poDropshipResource,
];

export const pagesRoutes: RouteObject[] = [
  dashboardRoutes,
  vendorRoutes,
  skuManagementRoutes,
  purchaseOrderRoutes,
  fakturPajakRoutes,
  warehouseRoutes,
  poDropshipRoutes,
];
